<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card border rounded-3 shadow-none" id="card_section">
          <div class="card-title p-3 rounded-top custom-page-header">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-bar-chart-line font-size-24"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 fw-normal">View outstanding balances, overdue days, and credit limits for each merchant.</small>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row d-none">
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="form-label mb-1">Merchant</label>
                <input
                    type="text"
                    placeholder="Username, Name, Company Name"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchKeyword"
                  />
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="form-label mb-1">Group</label>
                <multiselect
                  v-model="groupSelected"
                  :options="groups"
                  label="name"
                  class=""
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  select-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @input="handlePageChange(1)"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__title">{{
                        props.option.name
                      }}</span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <span>
                      {{ props.option.name }}
                    </span>
                  </template>
                  <span slot="noResult">Oops! No group found. </span>
                </multiselect>
                
              </div>
              <div class="col-12 col-md-6 col-lg mb-3">
                <label class="form-label mb-1">Sort By</label>
                    <select class="form-select pe-5" v-model="sortBy" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">Default</option>
                      <option value="name_a_z">Company Name (A-Z)</option>
                      <option value="name_z_a">Company Name (Z-A)</option>
                      <option value="username_a_z">Username (A-Z)</option>
                      <option value="username_z_a">Username (Z-A)</option>
                    </select>
              </div>
              <div class="col-12 col-md-6 col-lg mb-3">
                <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5" v-model="status" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="activate">Activate</option>
                      <option value="suspended">Suspended</option>
                      <option value="inactive">Inactive</option>
                    </select>
              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-md-auto col-lg-4 col-xl-auto">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-lg col-xl-4 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-primary text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !pageLoading}" ref="table">
                <thead class="text-uppercase">
                  <tr>
                    <th>Rank</th>
                    <th>Merchant Name</th>
                    <th>Company Name</th>
                    <th class="text-end">Total Sales</th>
                    <th class="text-end">Payments Received</th>
                    <th class="text-end">Outstanding Balance</th>
                    <th>Payment Terms</th>
                    <th>Due Date</th>
                    <th>Days Overdue</th>
                    <th>Account Status</th>
                    <!-- <th>Credit Limit</th> -->
                  </tr>
                </thead>
                <tbody class="text-muted">
                  <tr v-if="!returnData.length && !pageLoading">
                    <td :colspan="returnColSpan()" height="300" class="text-center text-muted">
                      No Data Available
                    </td>
                  </tr>
                  <tr v-if="pageLoading">
                    <td :colspan="returnColSpan()" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  
             
                  <tr v-for="(value, index) in returnData" :key="index" v-show="!pageLoading" >
                    <td>
                      {{ currentPage * perPage - perPage + index + 1 }}
                    </td>
                    <td> 
                      <router-link
                        :to="{
                           name: 'Edit Merchant',
                           params: { id: value.merchant.account_db_code },
                        }"
                        >
                        <div class="text-primary fw-medium">{{ value.merchant.first_name}} {{ value.merchant.last_name}}</div>
                      
                        </router-link
                      >
                    </td>
                    <td> 
                      <router-link
                        :to="{
                           name: 'Edit Merchant',
                           params: { id: value.merchant.account_db_code },
                        }"
                        >
                        <div class="text-primary fw-medium">{{ value.merchant.company_name}} </div>
                      
                        </router-link
                      >
                    </td> 
                    <td class="text-end"> 
                    {{convertCurrencyFormat(value.total_sales,true)}}
                    </td>
                    <td class="text-end"> 
                    {{convertCurrencyFormat(value.payment_received,true)}}
                    </td>
                    <td class="text-end"> 
                      {{convertCurrencyFormat(value.outstanding_balance,true)}}
                    </td>  
                    <td> 
                      {{ value.merchant.payment_terms.name }}
                    </td>   
                    <td> {{ value.due_date }}</td>
                    <td> {{ value.days_overdue }}</td>
                    <td> 
                      <div>
                        <span v-if="value.merchant.status=='inactive'" class="text-danger badge border border-danger fw-normal font-size-13 text-capitalize text-uppercase">{{value.merchant.status}}</span>
                        <span v-else-if="value.merchant.status=='activate'" class="text-success badge border border-success fw-normal font-size-13 text-capitalize text-uppercase"> {{value.merchant.status}}</span>
                        <span v-if="value.merchant.status=='suspended'" class="text-orange badge border border-orange fw-normal font-size-13 text-capitalize text-uppercase">{{value.merchant.status}}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <users ref="modalFunc2" :data="{accessUsername:accessUsername, assessToken:accessToken, updateData:updateData}" @callParentFunction="handlePageChange(currentPage)"/> 
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import users from "@/views/pages/app/modal/createEditUser";
/**
 * Page
 */
const PageTitle = "Outstanding Balance";
const groupDefault = { "id": "", "account_db_code": "", "name": "Selet Group" };
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    users,
    Multiselect
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      updateData:{},
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      account_type: '',
      showLottie: false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: false,
      pageLoading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      seletedMethod: "",
      sortBy: "",
      status: "",
      searchKeyword: "",
      groupSelected: groupDefault,
      groups:[],
      searchMerchantItemId: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      create_modify_permission: false

    };
  },
  middleware: "authentication",
  async mounted() {
    await this.reload()
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    this.getGroups();
    await this.handlePageChange(1);

  },
  created() {

  },
  methods: {
    returnColSpan() {
      const table = this.$refs.table;
      if (table){
        const columns = table.querySelectorAll('th');
        return columns.length;
      }
    },
    resetPassword(value){
     this.updateData = value;
     this.updateData.fromType = "merchantResetPassword" 
     setTimeout(() =>  this.$refs.modalFunc2.showMerchantResetPassword(),100)
    },
    getGroups() {
      console.log("getGroups")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/group_pricing_setting.json",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.groups = []
          this.groups = [groupDefault];
          //this.groups=resData.data;
          resData.data.forEach(element => {
            this.groups.push({
              id: element.id,
              account_db_code: element.account_db_code,
              name: element.name
            })
          });

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.searchKeyword);
      //this.exportData(1)
    },
    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.sortBy = ""
      this.searchKeyword = ""
      this.filterType = "created_date"
      this.date_range = this.$refs.commonFunc.last30Days()
      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = []
      objectMaxLength.forEach((value, index) => {
        console.log(value)
        wscols.push({ width: objectMaxLength[index] })
      }),
        ws['!cols'] = wscols;

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name)
      window.XLSX.writeFile(wb, title + '.' + (type || 'xlsx'))
    },
    async getData(pages, keyword) {
      this.$Progress.start();
      this.pageLoading = true
      this.disabled = true
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("keyword", keyword);
      bodyFormData.append("group", this.groupSelected.account_db_code);
      bodyFormData.append("sortBy", this.sortBy);
      bodyFormData.append("status", this.status);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(`page:${pages}, limit:${this.perPage}, keyword:${keyword}, group:${this.groupSelected.account_db_code}, sortBy:${this.sortBy}, dateStart:${this.date_range[0]}, dateEnd:${this.date_range[1]}`)
      axios({
        method: "get",
        url: `/api/outstanding_reports.json`,
        // method: "post",
        // url: appConfig.APIHost + 'controller/applicant/getAllApplicantList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.returnData = [];
          var resData = response.data;
          this.totalData = resData.total
          var responseData = resData.data

          if (resData.status == 200) {
            this.returnData = responseData
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.pageLoading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.pageLoading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    remove(value) {
      Swal.fire({
        icon: 'info',
        title: `Confirm Merchant Deletion?`,
        html: `<p>Are you sure you want to delete this ${value.company_name}? </p><p class="mb-0">This action is irreversible, so make sure you want to proceed.</p>`,
        confirmButtonColor: '#FA6541',
        iconColor: '#60b725',
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.loading = true
          this.disabled = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", value.account_db_code);
          axios({
            method: "post",
            url: appConfig.StagingAPI + "controller/applicant/deleteApplicant",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.handlePageChange(1)
              Swal.fire({
                icon: 'success',
                title: `${value.company_name} Deleted Successfully`,
                html: 'The selected applicant has been successfully deleted.',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              })
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            }
            this.loading = false
            this.disabled = false
            this.$Progress.finish();
          }).catch((error) => {
            this.disabled = false;
            this.loading = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      })


    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone", this.accessPhone);
      // bodyFormData.append("page", pages);
      // bodyFormData.append("limit", this.perPage);
      axios({
        method: "get",
        url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total
          var users = resData.users


          for (var i in users) {
            // Combine date and time
            const depositDateTime = `29 Jun 2023, 12:30:00`;
            // Generate a random deposit amount
            const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

            // Calculate merchant fee and nett amount
            const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
            const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
            const nettAmount = randomDeposit - merchantFee;
            const randomStatus = Math.random() < 0.8 ? "success" : "failed";
            var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
            var transaction_id = ""
            if (randomMode == "UPI") {
              transaction_id = "320020673657"
            } else {
              transaction_id = "IMPS/123443211240"
            }

            const listData = {
              "id": "ids",
              "deposit_date": depositDateTime,
              "customer_id": `${users[i].id}`,
              "customer_name": `${users[i].firstName} ${users[i].lastName}`,
              "deposit_amount": randomDeposit.toString(),
              "deposit_mode": randomMode.toString(),
              "merchant_fee": merchantFee.toString(),
              "nett_amount": nettAmount.toString(),
              "transaction_id": transaction_id,
              "status": randomStatus,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, 'Summary', 'xlsx')
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // } 
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name === 'reports')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission == 0 ? false : true;
          } else {
            console.log('Module with name "reports" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>